<template>
  <div>
    <el-button size="mini" type="success" @click="to">查看</el-button>
    <eForm ref="form" :sup_this="sup_this" :is-add="false"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form = {
        id: this.data.id,
        statDt: this.data.statDt,
        statType: this.data.statType,
        userId: this.data.userId,
        serverId: this.data.serverId,
        login: this.data.login,
        custId: this.data.custId,
        accountRating: this.data.accountRating,
        profitAbility: this.data.profitAbility,
        robustAbility: this.data.robustAbility,
        riskControlAbility: this.data.riskControlAbility,
        capitalSize: this.data.capitalSize,
        nonLuckyProfit: this.data.nonLuckyProfit,
        maxRetracement: this.data.maxRetracement,
        profitRate: this.data.profitRate,
        transferPeriod: this.data.transferPeriod,
        transferDays: this.data.transferDays,
        profitAmount: this.data.profitAmount,
        equity: this.data.equity,
        balance: this.data.balance,
        depositAmount: this.data.depositAmount,
        withdrawAmount: this.data.withdrawAmount,
        leverage: this.data.leverage,
        profitPoint: this.data.profitPoint,
        commission: this.data.commission,
        storageInt: this.data.storageInt,
        avgHoldTime: this.data.avgHoldTime,
        lossAversion: this.data.lossAversion,
        tranExpect: this.data.tranExpect,
        allLot: this.data.allLot,
        allCount: this.data.allCount,
        avgLot: this.data.avgLot,
        avgCount: this.data.avgCount,
        avgCountLot: this.data.avgCountLot,
        maxCountLot: this.data.maxCountLot,
        winPercent: this.data.winPercent,
        profitLossRate: this.data.profitLossRate,
        avgProfit: this.data.avgProfit,
        avgLoss: this.data.avgLoss,
        maxProfit: this.data.maxProfit,
        maxLoss: this.data.maxLoss,
        createTime: this.data.createTime,
        updateTime: this.data.updateTime
      }
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
