<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="dialog"
    :title="'查看'"
    width="1300px"
  >
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="150px" aria-readonly="true">
      <el-row>
        <el-col :span="6">
          <el-form-item label="统计日期">
            <el-input v-model="form.statDt" style="width: 150px;" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="佣金统计周期">
            <el-input v-model="form.statType" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="MT4用户唯一ID">
            <el-input v-model="form.userId" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="服务器ID">
            <el-input v-model="form.serverId" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="MT4账号">
            <el-input v-model="form.login" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="社区用户ID">
            <el-input v-model="form.custId" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="账户评级">
            <el-input v-model="form.accountRating" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="盈利能力">
            <el-input v-model="form.profitAbility" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="稳健能力">
            <el-input v-model="form.robustAbility" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="风控能力">
            <el-input v-model="form.riskControlAbility" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="资金规模">
            <el-input v-model="form.capitalSize" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="非侥幸盈利">
            <el-input v-model="form.nonLuckyProfit" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="最大回撤比例">
            <el-input v-model="form.maxRetracement" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="收益率">
            <el-input v-model="form.profitRate" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="交易周期">
            <el-input v-model="form.transferPeriod" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="交易天数">
            <el-input v-model="form.transferDays" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="收益">
            <el-input v-model="form.profitAmount" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="净值">
            <el-input v-model="form.equity" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="余额">
            <el-input v-model="form.balance" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="入金金额">
            <el-input v-model="form.depositAmount" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="出金金额">
            <el-input v-model="form.withdrawAmount" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="杠杆">
            <el-input v-model="form.leverage" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="盈利点数">
            <el-input v-model="form.profitPoint" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="手续费">
            <el-input v-model="form.commission" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="隔夜利息">
            <el-input v-model="form.storageInt" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="平均每笔持仓时间">
            <el-input v-model="form.avgHoldTime" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="损失厌恶系数">
            <el-input v-model="form.lossAversion" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="交易期望值">
            <el-input v-model="form.tranExpect" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="总手数">
            <el-input v-model="form.allLot" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="总笔数">
            <el-input v-model="form.allCount" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="日均交易手数">
            <el-input v-model="form.avgLot" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="日均交易笔数">
            <el-input v-model="form.avgCount" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="每笔平均手数">
            <el-input v-model="form.avgCountLot" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="最大单笔持仓手数">
            <el-input v-model="form.maxCountLot" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="交易胜率">
            <el-input v-model="form.winPercent" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="净值盈亏比">
            <el-input v-model="form.profitLossRate" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="获利单每笔平均">
            <el-input v-model="form.avgProfit" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="亏损单每笔平均">
            <el-input v-model="form.avgLoss" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="最大单笔获利">
            <el-input v-model="form.maxProfit" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="最大单笔亏损">
            <el-input v-model="form.maxLoss" style="width: 150px;" :disabled="true" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <!-- <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button> -->
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from "@/api/community/srSignalAccDetail";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      rules: [],
      form: {
        id: "",
        statDt: "",
        statType: "",
        userId: "",
        serverId: "",
        login: "",
        custId: "",
        accountRating: "",
        profitAbility: "",
        robustAbility: "",
        riskControlAbility: "",
        capitalSize: "",
        nonLuckyProfit: "",
        maxRetracement: "",
        profitRate: "",
        transferPeriod: "",
        transferDays: "",
        profitAmount: "",
        equity: "",
        balance: "",
        depositAmount: "",
        withdrawAmount: "",
        leverage: "",
        profitPoint: "",
        commission: "",
        storageInt: "",
        avgHoldTime: "",
        lossAversion: "",
        tranExpect: "",
        allLot: "",
        allCount: "",
        avgLot: "",
        avgCount: "",
        avgCountLot: "",
        maxCountLot: "",
        winPercent: "",
        profitLossRate: "",
        avgProfit: "",
        avgLoss: "",
        maxProfit: "",
        maxLoss: "",
        createTime: "",
        updateTime: ""
      }
    };
  },
  methods: {
    cancel() {
      this.resetForm();
    },
    doSubmit() {
      this.loading = true;
      if (this.isAdd) {
        this.doAdd();
      } else this.doEdit();
    },
    doAdd() {
      add(this.form)
        .then(res => {
          this.resetForm();
          this.$notify({
            title: "添加成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.$parent.$parent.init();
        })
        .catch(err => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    doEdit() {
      edit(this.form)
        .then(res => {
          this.resetForm();
          this.$notify({
            title: "修改成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.sup_this.init();
        })
        .catch(err => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    resetForm() {
      this.dialog = false;
      this.$refs["form"].resetFields();
      this.form = {
        id: "",
        statDt: "",
        statType: "",
        userId: "",
        serverId: "",
        login: "",
        custId: "",
        accountRating: "",
        profitAbility: "",
        robustAbility: "",
        riskControlAbility: "",
        capitalSize: "",
        nonLuckyProfit: "",
        maxRetracement: "",
        profitRate: "",
        transferPeriod: "",
        transferDays: "",
        profitAmount: "",
        equity: "",
        balance: "",
        depositAmount: "",
        withdrawAmount: "",
        leverage: "",
        profitPoint: "",
        commission: "",
        storageInt: "",
        avgHoldTime: "",
        lossAversion: "",
        tranExpect: "",
        allLot: "",
        allCount: "",
        avgLot: "",
        avgCount: "",
        avgCountLot: "",
        maxCountLot: "",
        winPercent: "",
        profitLossRate: "",
        avgProfit: "",
        avgLoss: "",
        maxProfit: "",
        maxLoss: "",
        createTime: "",
        updateTime: ""
      };
    },
  }
};
</script>

<style scoped>
</style>
