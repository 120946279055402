<template>
  <div class="app-container">
    <eHeader :query="query"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="statDt" label="统计日期"/>
      <el-table-column prop="statType" label="周期"/>
      <el-table-column prop="userId" label="UID"/>
      <el-table-column prop="login" label="MT4账号"/>
      <el-table-column prop="accountRating" label="账户评级"/>
      <el-table-column prop="maxRetracement" label="最大回撤比例"/>
      <el-table-column prop="profitRate" label="收益率"/>
      <el-table-column prop="transferPeriod" label="交易周期"/>
      <el-table-column prop="profitAmount" label="收益"/>
      <el-table-column prop="equity" label="净值"/>
      <el-table-column prop="balance" label="余额"/>
      <el-table-column label="操作" width="150px" align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','SIGNALACCDETAIL_ALL','SIGNALACCDETAIL_VIEW'])" :data="scope.row" :sup_this="sup_this"/>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { del } from '@/api/community/srSignalAccDetail'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/community/signal/signalaccdetail/header'
import edit from '@/components/community/signal/signalaccdetail/edit'
export default {
  name:'signalaccdetail',
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = 'community/crm/srSignalAccDetail'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort }
      const query = this.query
      // const userId = query.userId
      const login = query.login
      // if (userId !== "" && userId !== null) {
      //   this.params["userId"] = userId;
      // }
      if (login !== "" && login !== null) {
        this.params["login"] = login;
      }
      return true
    },
    subDelete(id) {
      this.delLoading = true
      del(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
